import React, { useState, useEffect } from "react"
import { Navigation } from "./Navigation"
import { DiabeticRed } from "../utils/colors"
import { rhythm, scale } from "../utils/typography"
import { Link } from "gatsby"
import { useMediaQuery } from "react-responsive"
import theme from "../utils/theme"
import { Jason } from "./Jason"

const BodyFontFamily = theme.bodyFontFamily

const Header = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: rhythm(1 / 2),
        paddingRight: rhythm(1 / 2),
        paddingTop: rhythm(1 / 2),
        paddingBottom: rhythm(1 / 2),
        borderTop: `solid 4px ${DiabeticRed}`,
      }}
    >
      <Link
        to="/"
        style={{
          height: 60,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "#eee",
            borderRadius: "50%",
            width: 40,
            height: 40,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <Jason />
        </div>
        {hasMounted && !isMobile && (
          <h1
            style={{
              marginLeft: rhythm(1 / 2),
              marginRight: 0,
              marginTop: 0,
              marginBottom: 0,
              fontFamily: BodyFontFamily,
              fontWeight: 100,
              fontStyle: "italic",
              ...scale(0.5),
            }}
          >
            Jason Tu
          </h1>
        )}
      </Link>
      <Navigation />
    </div>
  )
}

/**
 * Header that is used on freelance landing page.
 */
const FreelanceHeader = ({ styleProps = {} }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: rhythm(1 / 2),
        paddingRight: rhythm(1 / 2),
        paddingTop: rhythm(1 / 2),
        paddingBottom: rhythm(1 / 2),
        borderTop: `solid 4px ${DiabeticRed}`,
        ...styleProps,
      }}
    >
      <Link
        to="/"
        style={{
          height: 60,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            borderRadius: "50%",
            width: 40,
            height: 40,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <Jason />
        </div>
        <h1
          style={{
            marginLeft: rhythm(1 / 2),
            marginRight: 0,
            marginTop: 0,
            marginBottom: 0,
            fontFamily: BodyFontFamily,
            fontWeight: 100,
            fontStyle: "italic",
            ...scale(0.5),
            lineHeight: rhythm(2 / 3),
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div style={{ marginBottom: rhythm(1 / 8) }}>Jason Tu</div>
          <div>
            <small style={{ fontSize: ".9rem" }}>
              Senior Gameplay Engineer
            </small>
          </div>
        </h1>
      </Link>
      <Navigation showCta />
    </div>
  )
}

/**
 * Header that is used on freelance landing page.
 */
const FreelanceHeader2 = ({ styleProps = {} }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: rhythm(1 / 2),
        paddingRight: rhythm(1 / 2),
        paddingTop: rhythm(1 / 2),
        paddingBottom: rhythm(1 / 2),
        borderTop: `solid 4px ${DiabeticRed}`,
        ...styleProps,
      }}
    >
      <Link
        to="/"
        style={{
          height: 60,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/*
        <div
          style={{
            borderRadius: "50%",
            width: 40,
            height: 40,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <Jason />
        </div>
        */}
        <h1
          style={{
            marginLeft: rhythm(1 / 2),
            marginRight: 0,
            marginTop: 0,
            marginBottom: 0,
            fontFamily: BodyFontFamily,
            fontWeight: 100,
            fontStyle: "italic",
            ...scale(0.5),
            lineHeight: rhythm(2 / 3),
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div style={{ marginBottom: rhythm(1 / 8) }}>Jason Tu</div>
          <div>
            <small style={{ fontSize: ".9rem" }}>
              Game UI/UX Engineer
            </small>
          </div>
        </h1>
      </Link>
      <Navigation showCta={false} />
    </div>
  )
}

const GameCodebaseToursHeader = ({
  styleProps = {},
  avatarStyleProps = {},
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: rhythm(1 / 2),
        paddingRight: rhythm(1 / 2),
        paddingTop: rhythm(1 / 2),
        paddingBottom: rhythm(1 / 2),
        borderTop: `solid 4px ${DiabeticRed}`,
        ...styleProps,
      }}
    >
      <Link
        to="/"
        style={{
          height: 60,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            borderRadius: "50%",
            width: 40,
            height: 40,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
            ...avatarStyleProps,
          }}
        >
          <span role="img" aria-label="Technologist" style={{ fontSize: 24 }}>
            🦮
          </span>
        </div>
        <h1
          style={{
            marginLeft: rhythm(1 / 2),
            marginRight: 0,
            marginTop: 0,
            marginBottom: 0,
            fontFamily: BodyFontFamily,
            fontWeight: 100,
            fontStyle: "italic",
            ...scale(0.5),
            lineHeight: rhythm(2 / 3),
          }}
        >
          <div>Game Codebase Tours</div>
          <div>
            <small style={{ fontSize: ".9rem" }}>by Jason Tu</small>
          </div>
        </h1>
      </Link>
    </div>
  )
}

const EtudesForTheWebBrowserHeader = ({
  styleProps = {},
  avatarStyleProps = {},
  noBorder,
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: rhythm(1 / 2),
        paddingRight: rhythm(1 / 2),
        paddingTop: rhythm(1 / 2),
        paddingBottom: rhythm(1 / 2),
        borderTop: noBorder ? "none" : `solid 4px ${DiabeticRed}`,
        ...styleProps,
      }}
    >
      <Link
        to="/"
        style={{
          height: 60,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            borderRadius: "50%",
            width: 40,
            height: 40,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
            ...avatarStyleProps,
          }}
        >
          <span role="img" aria-label="Technologist" style={{ fontSize: 24 }}>
            🎹
          </span>
        </div>
        <h1
          style={{
            marginLeft: rhythm(1 / 2),
            marginRight: 0,
            marginTop: 0,
            marginBottom: 0,
            fontFamily: BodyFontFamily,
            fontWeight: 100,
            fontStyle: "italic",
            ...scale(0.5),
            lineHeight: rhythm(2 / 3),
          }}
        >
          <div>Etudes for the Web Browser</div>
          <div>
            <small style={{ fontSize: ".9rem" }}>by Jason Tu</small>
          </div>
        </h1>
      </Link>
    </div>
  )
}

const EtudesForTheWebBrowserHeaderNoLink = ({
  styleProps = {},
  avatarStyleProps = {},
  noBorder,
  chapter,
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: rhythm(1 / 2),
        paddingRight: rhythm(1 / 2),
        paddingTop: rhythm(1 / 2),
        paddingBottom: rhythm(1 / 2),
        borderTop: noBorder ? "none" : `solid 4px ${DiabeticRed}`,
        ...styleProps,
      }}
    >
      <div
        style={{
          height: 60,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            borderRadius: "50%",
            width: 40,
            height: 40,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
            ...avatarStyleProps,
          }}
        >
          <span role="img" aria-label="Technologist" style={{ fontSize: 24 }}>
            🎹
          </span>
        </div>
        <h1
          style={{
            marginLeft: rhythm(1 / 2),
            marginRight: 0,
            marginTop: 0,
            marginBottom: 0,
            fontFamily: BodyFontFamily,
            fontWeight: 100,
            fontStyle: "italic",
            ...scale(0.5),
            lineHeight: rhythm(2 / 3),
          }}
        >
          <div>Etudes for the Web Browser</div>
          <div>
            <small style={{ fontSize: ".9rem" }}>
              Chapter {chapter}, by Jason Tu
            </small>
          </div>
        </h1>
      </div>
    </div>
  )
}

export {
  Header,
  GameCodebaseToursHeader,
  EtudesForTheWebBrowserHeader,
  EtudesForTheWebBrowserHeaderNoLink,
  FreelanceHeader,
  FreelanceHeader2,
}
