import React, { useEffect, useState } from "react"
import { useMediaQuery } from "react-responsive"
import { rhythm } from "../utils/typography"

const ZeroMargin = {
  marginLeft: 0,
  marginRight: 0,
  marginTop: 0,
  marginBottom: 0,
}

const HorizontalPadding = {
  paddingLeft: rhythm(0.5),
  paddingRight: 0,
  paddingTop: rhythm(0.25),
  paddingBottom: rhythm(0.25),
}

const Navigation = ({ style, showCta }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  return (
    <nav className="Navigation" style={style}>
      <ul
        style={{
          listStyle: "none",
          margin: 0,
          display: "flex",
          flexWrap: "wrap",
          flexDirection: hasMounted && isMobile ? "column" : "row",
          alignItems: "center",
        }}
      >
        {/*
        <li style={{ ...ZeroMargin, ...HorizontalPadding }}>
          <Link
            to="/blog"
            style={{
              color: DiabeticRed,
            }}
          >
            Blog
          </Link>
        </li>
        */}
        {/*
        <li style={{ ...ZeroMargin, ...HorizontalPadding }}>
          <a
            href="https://www.linkedin.com/in/jasontu/recent-activity/shares/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: DiabeticRed }}
          >
            LinkedIn
          </a>
        </li>
        */}
        {/*
        <li style={{ ...ZeroMargin, ...HorizontalPadding }}>
          <a
            href="https://twitter.com/nucleartide"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: DiabeticRed }}
          >
            Twitter
          </a>
        </li>
        */}
        {/*
        <li style={{ ...ZeroMargin, ...HorizontalPadding }}>
          <a
            href="https://youtube.com/nucleartide"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: DiabeticRed }}
          >
            YouTube
          </a>
        </li>
        */}
        {showCta && (
          <li style={{ ...ZeroMargin, ...HorizontalPadding }}>
            <div className="FreelanceEmailSignup">
              <a
                href="https://forms.gle/p5quCkVcqKZSFcws6"
                rel="noreferrer"
                target="_blank"
                style={{
                  backgroundColor: "#f44336",
                  color: "white",
                  borderRadius: 5,
                  border: "none",
                  textAlign: "center",
                  display: "block",
                  paddingTop: rhythm(1 / 3),
                  paddingBottom: rhythm(1 / 3),
                  paddingLeft: rhythm(2 / 3),
                  paddingRight: rhythm(2 / 3),
                  cursor: "pointer",
                }}
              >
                Talk with me ↗
              </a>
            </div>
          </li>
        )}
      </ul>
    </nav>
  )
}

export { Navigation }
