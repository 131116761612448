import React from "react"
import { useMediaQuery } from "react-responsive"
import { rhythm } from "../utils/typography"
import { Gray } from "../utils/colors"
import { OpenInNewTab } from './OpenInNewTab'

function Footer() {
  const isMobile = useMediaQuery({ maxWidth: 767 })

  return (
    <footer
      style={{
        textAlign: "center",
        color: Gray,
        marginBottom: isMobile ? rhythm(1 / 2) : rhythm(1),
      }}
    >
      <small>© {new Date().getFullYear()}, Jason Tu LLC</small>
      &nbsp;
      ·
      &nbsp;
      <small><OpenInNewTab href="https://linkedin.com/in/jasontu">LinkedIn</OpenInNewTab></small>
      &nbsp;
      ·
      &nbsp;
      <small><OpenInNewTab href="https://twitter.com/nucleartide">Twitter</OpenInNewTab></small>
      &nbsp;
      ·
      &nbsp;
      <small><OpenInNewTab href="https://youtube.com/nucleartide">YouTube</OpenInNewTab></small>
    </footer>
  )
}

export { Footer }
