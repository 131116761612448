import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

// https://developer.twitter.com/en/docs/tweets/optimize-with-cards/overview/summary-card-with-large-image
function twitterMetadata({ title, description, imageUrl, imageAlt }) {
  if (!title) throw new Error(`Missing title.`)
  if (!description) throw new Error(`Missing description.`)
  if (!imageUrl) throw new Error(`Missing image URL.`)
  if (!imageAlt) throw new Error(`Missing image alt text.`)

  return [
    {
      name: `twitter:card`,
      content: `summary_large_image`,
    },
    {
      name: `twitter:site`,
      content: `@nucleartide`,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: description,
    },
    {
      name: `twitter:image`,
      content: imageUrl,
    },
    {
      name: `twitter:image:alt`,
      content: imageAlt,
    },
    {
      name: `og:image`,
      content: imageUrl,
    },
    {
      name: `og:image:alt`,
      content: imageAlt,
    },
    {
      // Support LinkedIn.
      name: `image`,
      property: "og:image",
      content: imageUrl,
    },
  ]
}

function metadata({ title, description, ...otherProps }) {
  if (description === undefined) throw new Error(`Missing description.`)
  if (title === undefined) throw new Error(`Missing title.`)

  return [
    {
      // Page description.
      name: `description`,
      content: description,
    },
    {
      // Page title.
      property: `og:title`,
      content: title,
    },
    {
      // Page description.
      property: `og:description`,
      content: description,
    },
    {
      // Open Graph shit.
      property: `og:type`,
      content: `website`,
    },
    // Twitter shit.
    ...twitterMetadata({ title, description, ...otherProps }),
  ]
}

function SEO(props) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  const { title, lang } = props
  if (title === null || title === undefined) {
    throw new Error(`Title is required.`)
  }

  const formattedTitle = title
    ? `${title} | ${site.siteMetadata.title}`
    : site.siteMetadata.title

  // Hack to highlight code once the JavaScript loads.
  React.useEffect(() => {
    const interval = setInterval(() => {
      if (window.hljs) {
        console.log('Highlighting syntax...')
        window.hljs.highlightAll()
        clearInterval(interval)
      }
    }, 1 * 500)

    return () => clearInterval(interval)
  }, [])

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={formattedTitle}
      meta={metadata({ ...props, title: formattedTitle })}
    >
      <link
        rel="stylesheet"
        href="//cdnjs.cloudflare.com/ajax/libs/highlight.js/11.5.1/styles/a11y-light.min.css"
      />
      <script src="//cdnjs.cloudflare.com/ajax/libs/highlight.js/11.5.1/highlight.min.js"></script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export { SEO }
