import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { rhythm } from "../utils/typography"

// <Jason /> is a picture of me.
const Jason = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "jason.jpg" }) {
        childImageSharp {
          fixed(width: 40, height: 40) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Img
      fixed={data.file.childImageSharp.fixed}
      style={{ borderRadius: "50%" }}
    />
  )
}

const JasonWithText = (stuff) => (
  <div style={{
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: rhythm(1),
    marginBottom: rhythm(1),
  }} {...stuff}>
    <Jason />
    <div style={{ fontStyle: 'italic' }}>Jason Tu</div>
  </div>
)

export { Jason, JasonWithText }
